<template>
  <BaseLoader v-if="!$store.getters.isLoaded(['shelves', 'products'])" />
  <div v-else>
    <ul v-if="nextExpiringItems">
      <li v-for="(item, i) in nextExpiringItems" :key="i">
        {{ item.expiryDate }}: {{ item.amount }}x {{ $store.getters.product(item.productId).brandName }}
        {{ $store.getters.product(item.productId).label }}
        <small>{{ item.space }}</small>
      </li>
    </ul>
    <h1>Warehouse</h1>
    <button class="btn btn-secondary" style="margin: 0 1rem 1rem 0" @click="createShelf">Create Shelf</button>
    <button class="btn btn-secondary" style="margin: 0 1rem 1rem 0" @click="copyItems">Copy old Items</button>
    <button class="btn btn-secondary" style="margin: 0 1rem 1rem 0" @click="cleanUpItems">Cleanup empty Items</button>
    <button class="btn btn-secondary" style="margin: 0 1rem 1rem 0" @click="addSpaces">Add Spaces</button>
    <ul>
      <li v-for="shelf in $store.state.shelves" :key="shelf.id">
        {{ shelf.id }}
        <ul>
          <li v-for="(space, spaceId) in shelf.spaces" :key="spaceId">
            {{ spaceId }}
            <ul>
              <li v-for="(item, i) in space.items" :key="i">{{ item.amount }}x {{ item.productId }}</li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import { db } from '@/shared/firebase'
export default {
  methods: {
    createShelf: function() {
      const shelfId = prompt('ID? (A1--)')
      const rowCount = 6
      // const rowCount = parseFloat(prompt('Number of Rows? (--A-)', 6))
      const columnCount = 1
      // const columnCount = parseFloat(prompt('Number of Columns? (---1)', 2))
      if (shelfId && rowCount && columnCount) {
        const shelf = {
          spaces: {}
        }
        for (let rowIndex = 0; rowIndex < rowCount; rowIndex++) {
          for (let columnIndex = 0; columnIndex < columnCount; columnIndex++) {
            const spaceId = `${rowIndex}${columnIndex}`
            shelf.spaces[spaceId] = {
              items: []
            }
          }
        }
        db.doc(`private/warehouse/shelves/${shelfId}`).set(shelf)
      }
    },
    copyItems: function() {
      const shelfId = prompt('Shelf ID?')
      if (shelfId) {
        const newSpace = _.cloneDeep(_.find(this.$store.state.shelves, { id: shelfId }))
        db.doc(`private/warehouse/shelves/${shelfId}`)
          .get()
          .then(doc => {
            const oldData = doc.data()
            const alpha = 'ABCDEF'
            Object.keys(oldData.spaces).forEach(spaceId => {
              newSpace.spaces[`${alpha.indexOf(spaceId.substr(0, 1)) + 1}${spaceId.substr(1, 1)}`] =
                oldData.spaces[spaceId]
            })
            db.doc(`private/warehouse/shelves/${shelfId}`).update(newSpace)
          })
      }
    },
    cleanUpItems: function() {
      const shelves = this.$store.state.shelves
      shelves.forEach(shelf => {
        const shelfData = _.cloneDeep(shelf)
        Object.keys(shelfData.spaces).forEach(spaceId => {
          let items = _.cloneDeep(shelfData.spaces[spaceId].items)
          items = items.filter(item => item.amount > 0)
          shelfData.spaces[spaceId].items = items
        })
        db.doc(`private/warehouse/shelves/${shelf.id}`).update(shelfData)
      })
    },
    addSpaces: function() {
      const spaceId = prompt('Space ID?')
      if (spaceId) {
        const shelves = this.$store.state.shelves
        shelves.forEach(shelf => {
          db.doc(`private/warehouse/shelves/${shelf.id}`).update({
            [`spaces.${spaceId}`]: {
              items: []
            }
          })
        })
      }
    }
  },
  computed: {
    nextExpiringItems() {
      const itemsToExpire = []
      this.$store.state.shelves.forEach(shelf => {
        Object.keys(shelf.spaces).forEach(spaceId => {
          shelf.spaces[spaceId].items.forEach(item => {
            if (item.expiryDate) {
              item.space = `${shelf.id}${spaceId}`
              itemsToExpire.push(item)
            }
          })
        })
      })
      return _.sortBy(itemsToExpire, 'expiryDate').slice(0, 10)
    }
  }
}
</script>
